import styled from 'styled-components';
import { NotificationProvider } from '@/hooks/NotificationHook';

const GuestLayout = ({ children }) => (
    <NotificationProvider>
        <Component>
            <Patrone src={'/assets/images/patrone.png'} />

            <Body>{children}</Body>
        </Component>
    </NotificationProvider>
);

const Component = styled.div`
    position: relative;
    height: 100vh;
    background: linear-gradient(180deg, #145782 0%, #d1d1d1 0%, #0d4c74 0%, #15649f 100%);
    overflow: hidden;
`;

const Patrone = styled.img`
    position: absolute;
    top: -5vw;
    left: -5vw;
    z-index: 0;
    width: 100%;
    aspect-ratio: 1;
`;

const Body = styled.main`
    position: absolute;
    width: 100%;
    z-index: 1;
`;

export default GuestLayout;
